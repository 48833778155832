.datatable_wrapper {
    position: relative;
}
.MuiModal-root {
    overflow-y: auto;
}
.primary_container {
    min-height: 80vh;
}
.modal-dialog {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    width: auto;
    margin: 1.75rem auto;  
    box-shadow: none !important;
    background-color: transparent !important;
}
@media (min-width: 992px) {
    .modal-lg, .modal-xl {
        max-width: 800px;
    }
}
@media (min-width: 576px) {
    .modal-dialog {
        max-width: 500px;        
        min-height: calc(100% - (1.75rem * 2));
    }
    .modal-sm {
        max-width: 300px;
    }
}

.modal-content {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: 0.6rem;
    box-shadow: 1px 3px 11px 0 rgb(10 22 36 / 20%);
}
.close_button {
    position: absolute !important;
    right: 10px;
    top: 10px;
}
.modal-header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid #e9ecef;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
}
.modal-body {
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1rem;
}
.modal-footer {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding: 1rem;
    border-top: 1px solid #e9ecef;
}

.mb-3 {
    margin-bottom: 1.8rem !important;
}

body .css-1cohrqd-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
    padding: 0.75rem !important;
}

.data-table .sc-cwHptR {
    overflow: visible;
    white-space: normal;
    text-overflow: unset;
}
#root .data-table-extensions {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
}
#root .data-table-extensions > .data-table-extensions-filter > .filter-text {
    border: 0;
}
#root .data-table-extensions > .data-table-extensions-filter {
    float: left;
    border: 1px solid #e0e0e0;
    border-radius: 0.4rem;
    display: flex;
    align-items: center;
}
#root .data-table-extensions > .data-table-extensions-filter > .icon {
    width: 32px;
    height: 32px;
    background-position: center;
}
.form-label {
    font-size: 14px !important;
    text-transform: capitalize;
}
.form-label p {
    display: inline-block;
    line-height: normal;
}
.MuiFormControl-root .css-r5uj9a-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
    padding: 3.5px 4px 3.5px 6px;
}
.css-1kvvgjv-MuiSvgIcon-root-MuiSelect-icon,
.css-ixc2ow-MuiSvgIcon-root-MuiSelect-icon {
    display: inline-block !important;
}