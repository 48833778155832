.describe_form label{
    display: block;
    font-size: 20px;
    color: #1c1c1c;
    font-weight: 600;
}


.describe_form input[type="email"]{
    width: 100%;
    height: 50px;
    border: 1px solid #1c1c1c;
    border-radius: 10px;
    padding: 0 10px;
    font-size: 18px;
}

.describe_form input[type="email"]::placeholder{
    font-size: 18px;
}
.email_modal  .close_buutt, .email_modal1  .close_buutt{
    position: absolute !important;
    width: 30px !important;
    min-width: 30px !important;
    height: 30px !important;
    padding: 0px !important;
    background: #f00 !important;
    color: #fff !important;
    font-size: 18px !important;
    display: inline-block !important;
    text-align: center !important;
    line-height: 30px !important;
    border-radius: 100% !important;
    top: -20px !important;
    right: -20px !important;
    border: 0px !important;
    cursor: pointer !important;
}



.org_modalll p{
    text-align: center;

}


.btn__group .yes_but{
    background: #098219;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    height: 40px;
    line-height: 40px;
    padding: 0 30px;
    display: inline-block;
    margin: 0 10px;
}
.btn__group .yes_but:hover{
    background: #098219;
}

.btn__group .no_but{
    background: #e22103;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    height: 40px;
    line-height: 40px;
    padding: 0 30px;
    display: inline-block;
    margin: 0 10px;
}

.btn__group .no_but:hover{
    background: #e22103;
}
.MuiTable-root .MuiBox-root{
    width: auto !important;
    margin-left: 0px !important;
}
.btn__group{
    text-align: center;
    margin-top: 20px;
}

.menu_drop_down  label{
    padding: 0px;
    position: relative;
}
.MuiTable-root .MuiAvatar-root{
    display: none;
}
.describe_form{
 margin-bottom: 30px;
}
.btn_group{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.email_modal1 .email_modal{
    margin-left: -25px;
}


.email_modal1 .email_modal .MuiBox-root{
    margin-bottom: 10px;
}

.email_modal1 .MuiBox-root{
    position: static;
    border: 0px;
    margin-left: 0px;
    margin-bottom: 5px;
}


.alert__box{
    width: 100%;
    height: 60px;
    justify-content: center !important;
    display: block !important;
}

.alert__box p{
    color: #fff;
    font-weight: 600;
    text-align: center;
}

.alert__box .MuiBox-root{
    display: block;
}

.org_image img {
    max-height: 40px;
    object-fit: contain;
}