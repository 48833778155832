.loader-wrapper{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader-wrapper:before{
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0,0,0,0.5);
    z-index: 9999;
}


.loader-wrapper svg{
    z-index: 999999;
}