.profile_icon img {
    object-fit: cover;
    border-radius: 50%;
}
.profile_icon {
    width: 40px;
    padding: 0;
    height: 40px;
    cursor: pointer;
}
